import React, { useEffect, useState, useRef } from 'react'
import { withStyles } from '@material-ui/core'
import { connect } from 'react-redux'

import MessageGroup from '../MessageGroup/MessageGroup'

import { getMessages, setMessageRead } from '../../api/user'
import { saveSupportUserMessages, saveSupportUserMoreMessages } from '../../actions/activeUser'

import { styles } from './styles'
import LoaderSmall from 'uiKit/loaders/loaderSmall'
import { formatDate } from 'helpers/formatDate/formatDate'
import { updateUserUnreadMessages } from '../../actions/users'
import { usePrevious } from 'hooks/usePrevious'
import { isElementTop } from 'helpers/isElementTop'
import { ChatUserType } from 'models/ChatMessagesTypes'
import { SupportUserType } from 'models/SupportUserType'

interface Props {
  classes: any
  user: ChatUserType & SupportUserType
  activeBot: any
}

const MessagesWrap: React.FC<Props> = props => {
  const { classes, user, activeBot } = props
  const [scrollPosition, setScrollPosition] = useState(null)
  const [scrollToTop, serScrollToTop] = useState(false)
  const [dates, setDates] = useState([])
  const [page, setPage] = useState(0)
  const [hasNext, setHasNext] = useState(true)
  const [loading, setLoading] = useState(true)
  const scrollContainer = useRef(null)
  const messagesEnd = useRef(null)
  const messageIntoView = useRef(null)
  const prevUserMessagesLength = usePrevious(user.messages?.length)

  useEffect(() => {
    if (user.chatId && activeBot?.id) {
      getMessages(user.chatId, 0, activeBot.id)
        .then(saveSupportUserMessages)
        .then(() => {
          setLoading(false)
          setPage(1)
        })
        .then(scrollToBottom)
        .catch(() => setLoading(false))
    }
  }, [user.chatId])

  useEffect(() => {
    if (scrollToTop && scrollPosition) {
      scrollContainer.current.scrollTop = scrollContainer.current.scrollHeight - scrollPosition
    }

    if (user.messages?.length) {
      updateMessageDates()
      updateMessageRead()

      if (user.messages?.length - prevUserMessagesLength === 1) {
        scrollToBottom()
      }
    } else {
      setDates([])
    }
  }, [user.messages?.length, user.chatId])

  const updateMessageDates = () => {
    const dates = user.messages.map(item => item.lastEventAt?.split('T')[0])
    setDates([...new Set(dates)])
  }

  const updateMessageRead = () => {
    const lastMessage = user?.messages[0]

    if (lastMessage?.chatHistoryId && user?.chatId) {
      setMessageRead(user.chatId, activeBot.id).then(() => {
        updateUserUnreadMessages(user)
      })
    }
  }

  const scrollToBottom = () => {
    if (messagesEnd.current) {
      messagesEnd.current.scrollIntoView()
    }
  }

  const handleScroll = ({ target }) => {
    if (isElementTop(target) && hasNext && target === scrollContainer.current && user.chatId) {
      setScrollPosition(scrollContainer.current.scrollHeight)
      serScrollToTop(true)
      getMessages(user.chatId, page, activeBot.id)
        .then(saveSupportUserMoreMessages)
        .then(data => {
          setPage(page + 1)
          updateHasNext(data.content.length)
        })
        .then(scrollToBottom)
        .catch(() => setLoading(false))
    }
  }

  const updateHasNext = messagesLength => {
    if (messagesLength < 10) {
      setHasNext(false)
    }
  }

  return (
    <>
      {loading ? (
        <div className={classes.loaderWrap}>
          <LoaderSmall showLoader={true} />
        </div>
      ) : (
        <div
          className={classes.container}
          style={{ height: activeBot.voice ? 'calc(100% - 76px)' : 'calc(100% - 126px)' }}
          ref={scrollContainer}
          onScroll={handleScroll}>
          {dates
            .slice()
            .reverse()
            .map(date => (
              <div key={date}>
                <p className={classes.date}>{formatDate(date)}</p>
                <MessageGroup date={date} messages={user.messages} messageIntoView={messageIntoView} />
              </div>
            ))}
          <div className={classes.bottomScrollElem} ref={messagesEnd} />
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  user: state.activeUser,
  activeBot: state.activeBot,
})

export default withStyles(styles)(connect(mapStateToProps)(MessagesWrap))
