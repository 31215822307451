import React from 'react'
import Heading from 'uiKit/texts/Heading'
import Paragraph from 'uiKit/texts/Paragraph'
import Switch from 'uiKit/Switch'

import { toggleContextualConversations } from '../../../api/settings'

import * as S from './ContextualConversationsBlock.styles'

export const ContextualConversationsBlock = ({ activeBot }) => {
  const handleToggleContextualConversations = e => {
    toggleContextualConversations(activeBot.id, e.target.checked)
  }

  return (
    !activeBot.voice && (
      <S.ContextualConversationsBlock>
        <Heading>Contextual conversations</Heading>
        <Paragraph>
          Chatbot will use the context of a couple of previous chat messages to find the answer for the next one.
        </Paragraph>
        <Switch
          useSecondaryColor={true}
          label="Enable contextual conversations"
          checked={activeBot?.contextualizerEnabled}
          onChange={handleToggleContextualConversations}
        />
      </S.ContextualConversationsBlock>
    )
  )
}
