import React, { useEffect } from 'react'
import { withStyles } from '@material-ui/core'
import PropTypes from 'prop-types'

import ChatHeader from '../ChatHeader/ChatHeader'
import MessagesWrap from '../MessagesWrap'
import ChatInput from '../ChatInput/ChatInput'

import { styles } from './styles'
import { clearActiveUser } from '../../actions/activeUser'
import { connect } from 'react-redux'

const ChatContainer = props => {
  const { classes, bot } = props

  useEffect(() => {
    return () => {
      clearActiveUser()
    }
  }, [])

  return (
    <div className={classes.container}>
      <ChatHeader />
      <MessagesWrap />
      {!bot.voice && <ChatInput />}
    </div>
  )
}

ChatContainer.propTypes = {
  classes: PropTypes.object,
}

const mapStateToProps = state => ({
  bot: state.activeBot,
})

export default withStyles(styles)(connect(mapStateToProps)(ChatContainer))
