import React, { FC, ChangeEvent } from 'react'
import AutoResizingTextarea from 'uiKit/AutoResizingTextarea/AutoResizingTextarea'

import * as S from './GreetingForm.style'

const textareaStyle = {
  fontSize: '14px',
}

interface GreetingFormProps {
  placeholderText: string
  greetingText: string
  greetingError?: string
  onGreetingChange: (e: ChangeEvent<HTMLTextAreaElement>) => Promise<void>
}

export const GreetingForm: FC<GreetingFormProps> = ({
  placeholderText,
  greetingText,
  greetingError,
  onGreetingChange,
}) => {
  return (
    <S.Container>
      <S.Title>Customize greeting</S.Title>
      <S.Form>
        <AutoResizingTextarea
          minHeight={68}
          maxHeight={600}
          placeholder={placeholderText}
          value={greetingText}
          onChange={onGreetingChange}
          isError={!!greetingError}
          style={textareaStyle}
        />
        <S.Error>{greetingError}</S.Error>
      </S.Form>
    </S.Container>
  )
}
