import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { withStyles } from '@material-ui/core'
import Input from '../../../../../uiKit/inputs/Input'
import { getTwilioCredentials, submitTwilioCredentials } from '../../../api/twilioCredetials'
import { alertError } from '../../../../../api'
import { RadioButtonGroup } from './RadioButtonGroup/RadioButtonGroup'
import Flag from 'react-world-flags'
import { VOICE_BASE_URL } from '../../../../../configs'
import { CopyIcon } from '../../../../../uiKit/icons/Icons'
import { copyValueToClipboard } from '../../../../../helpers/copyValueToClipboard'

const styles = theme => ({
  description: {
    fontSize: '14px',
    color: 'var(--color-text-secondary)',
    fontFamily: 'Lato, sans-serif',
    margin: '0 0 8px 0',
  },
  connectionBtn: {
    color: 'var(--color-button-primary)',
    border: '1px solid var(--color-button-primary)',
    backgroundColor: '#fff',
    borderRadius: '10px',
    padding: '8px 16px',
    cursor: 'pointer',
    '&:disabled': {
      backgroundColor: 'var(--color-athens-grey)',
      color: 'var(--color-mischka-grey)',
      borderColor: 'var(--color-mischka-grey)',
    },
  },
  statusContent: {
    fontSize: '17px',
    lineHeight: '10px',
  },
  statusBlock: {
    marginBottom: '35px',
    width: '400px',
  },
  popupBtnBlock: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '40px',
  },
  instructionBtn: {
    color: 'var(--color-button-primary)',
    cursor: 'pointer',
    margin: '0 0 0 8px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  secondaryTitle: {
    fontSize: 14,
    color: theme.defaults.headerText,
    fontWeight: 400,
    margin: '24px 0 8px',
  },
})

const showConnectionText = connection => {
  return connection ? 'Connected' : 'Not connected'
}
const showConnectionStyle = connection => {
  return { color: connection ? 'var(--color-malachite-green)' : 'var(--color-spun-pearl-grey)' }
}
const inputStyle = {
  marginBottom: '24px',
  width: '400px',
}

const REGION_TO_BASE_URL = {
  US1: 'https://api.twilio.com',
  IE1: 'https://api.dublin.ie1.twilio.com',
  AU1: 'https://api.sydney.au1.twilio.com',
}

const BASE_URL_TO_REGION = {
  'https://api.twilio.com': 'US1',
  'https://api.dublin.ie1.twilio.com': 'IE1',
  'https://api.sydney.au1.twilio.com': 'AU1',
}

const DEFAULT_REGION = 'US1'

const TwilioVoiceIntegration = ({ activeBot, twilioCredentials, classes }) => {
  const [credentials, setCredentials] = useState({
    accountSID: '',
    authToken: '',
    authTokenSID: '',
    connected: false,
    apiBaseUrl: REGION_TO_BASE_URL.US1,
  })
  const [buttonDisabled, setButtonDisabled] = useState(true)

  const [error, setError] = useState({
    accountSIDError: false,
    authTokenError: false,
    authTokenSIDError: false,
  })

  useEffect(() => {
    getTwilioCredentials(activeBot.id)
  }, [])

  useEffect(() => {
    const newCredentials = { ...credentials, ...twilioCredentials }
    setCredentials(newCredentials)
  }, [twilioCredentials])

  useEffect(() => {
    const fieldsFilled = credentials?.accountSID?.length && credentials?.authToken?.length
    const shouldDisableButton = !fieldsFilled || (credentials.connected && !credentialsChanged())
    setButtonDisabled(shouldDisableButton)
  }, [credentials, twilioCredentials])

  const credentialsChanged = () => {
    const { accountSID, authToken, authTokenSID, apiBaseUrl } = twilioCredentials
    return (
      accountSID !== credentials.accountSID ||
      authToken !== credentials.authToken ||
      authTokenSID !== credentials.authTokenSID ||
      apiBaseUrl !== credentials.apiBaseUrl
    )
  }

  const submitCredentials = async event => {
    setButtonDisabled(true)
    event.preventDefault()

    const currentError = {
      accountSIDError: credentials.accountSID === '',
      authTokenError: credentials.authToken === '',
    }

    if (currentError.accountSIDError) {
      alertError('Please enter account SID')
    }
    if (currentError.authTokenError) {
      alertError('Please enter account access token')
    }

    if (!currentError.accountSIDError && !currentError.authTokenError) {
      await submitTwilioCredentials(activeBot.id, credentials, true)
      await getTwilioCredentials(activeBot.id)
    }

    setError(currentError)
  }

  const handleChange = (event, key) => {
    const newCredentials = { ...credentials }
    const value = event.target.value
    console.log('handleChange. event:', event, 'key:', key)
    if (key === 'region') {
      newCredentials.apiBaseUrl = REGION_TO_BASE_URL[value]
    } else {
      newCredentials[key] = value
    }
    setCredentials(newCredentials)
  }

  const webhookUrl = VOICE_BASE_URL + '/bots/' + activeBot.publicIdentifier + '/incoming-call'

  return (
    <div>
      <div className={classes.statusBlock}>
        <span>
          <p className={classes.description}>Status:</p>
          <p className={classes.statusContent} style={showConnectionStyle(credentials.connected)}>
            {showConnectionText(credentials.connected)}
          </p>
        </span>
      </div>
      <form onSubmit={submitCredentials}>
        <Input
          title={'Account SID'}
          error={error.accountSIDError}
          wrapperStyle={inputStyle}
          type={'text'}
          placeholder={'Enter account SID'}
          value={credentials.accountSID || ''}
          onChange={value => handleChange(value, 'accountSID')}
        />
        <Input
          title={'Account access token'}
          wrapperStyle={inputStyle}
          error={error.authTokenError}
          placeholder={'Enter account access token (access key)'}
          value={credentials.authToken || ''}
          onChange={value => handleChange(value, 'authToken')}
        />
        <Input
          title={'Auth token SID (Optional)'}
          wrapperStyle={inputStyle}
          error={error.authTokenSIDError}
          placeholder={'Enter auth token SID'}
          value={credentials.authTokenSID || ''}
          onChange={value => handleChange(value, 'authTokenSID')}
        />
        <div style={{ marginTop: '24px' }}>
          <span className={classes.secondaryTitle}>Region</span>
          <span style={{ marginLeft: '8px' }}>
            <Flag code={(BASE_URL_TO_REGION[credentials.apiBaseUrl] || DEFAULT_REGION).substring(0, 2)} height="24" />
          </span>
          <RadioButtonGroup
            value={BASE_URL_TO_REGION[credentials.apiBaseUrl] || DEFAULT_REGION}
            onChange={value => handleChange({ target: { value } }, 'region')}
          />
          <div className={classes.secondaryTitle}>
            <span className="fw-bold">Webhook URL</span>
            <p style={{ color: 'var(--color-spun-pearl-grey)', fontSize: '12px' }}>
              Copy webhook url and add to Twilio phone number voice configuration to enable incoming calls
            </p>
            <div role="button" onClick={() => copyValueToClipboard(webhookUrl)}>
              {webhookUrl}
              <span className="ps-2">
                <CopyIcon color="var(--color-spun-pearl-grey)" width={16} />
              </span>
            </div>
          </div>
        </div>

        <div className={classes.popupBtnBlock}>
          <button className={classes.connectionBtn} type="submit" disabled={buttonDisabled}>
            Connect
          </button>
        </div>
      </form>
    </div>
  )
}

const mapStateToProps = state => ({
  twilioCredentials: state.twilioCredentials,
  activeBot: state.activeBot,
})

export default withStyles(styles, { withTheme: true })(connect(mapStateToProps)(TwilioVoiceIntegration))
