import React, { useEffect, useState } from 'react'
import Select from 'react-select'

import { hasPermissionForBot } from '../../../../../security'

import { alertSuccess } from 'api'

import {
  getDefaultCustomPrompt,
  getNLPConfig,
  getAvailableGPTModels,
  saveNLPConfig,
} from 'tabs/nlp/api/websiteKnowledgeBase'

import PromptForm from '../PromptForm'

import * as S from './SettingsTab.style'
import { customStyles } from './SettingsTab.style'

import { usePrompt } from '../../hooks/usePrompt'

type GPTModelOption = { value: string; label: string } | undefined

const placeholderOptions = [
  'You are a smart AI assistant who ...',
  'You are a caribbean pirate who was never caught ...',
  'You are a librarian that loves recommending books ...',
  'You are a wizard that predicts future based on your knowledge ...',
]

export const SettingsTab = ({ activeBot }): JSX.Element => {
  const [enableSaveConfig, setEnableSaveConfig] = useState(false)
  const [GPTModel, setGPTModel] = useState<GPTModelOption>()
  const [originalGPTModel, setOriginalGPTModel] = useState<GPTModelOption>()
  const [modelOptions, setModelOptions] = useState<GPTModelOption[]>([])

  const [originalPrompt, setOriginalPrompt] = useState('')
  const [defaultPrompt, setDefaultPrompt] = useState('')

  useEffect(() => {
    Promise.all([getAvailableGPTModels(), getNLPConfig(activeBot.id)]).then(([GPTModels, nlpConfig]) => {
      // Convert the raw model names into { value, label } pairs
      const optimizedOptions = GPTModels?.map(modelName => ({ label: modelName, value: modelName }))
      const selectedOption = optimizedOptions.find(model => model.value === nlpConfig.model)

      setModelOptions(optimizedOptions)
      setGPTModel(selectedOption)
      setOriginalGPTModel(selectedOption)

      setOriginalPrompt(nlpConfig.prompt || '')
    })
  }, [activeBot.id])

  useEffect(() => {
    if (originalPrompt) {
      getDefaultPrompt().then(result => setDefaultPrompt(result))
    }
  }, [originalPrompt])

  const getDefaultPrompt = async (): Promise<string> => {
    return await getDefaultCustomPrompt()
  }

  const maxPromptLength = 10000

  const {
    promptText,
    promptPlaceholderText,
    promptError,
    setPromptError,
    displayResetNote,
    enableResetDefault,
    handlePromptChange,
    handleResetToDefault,
    setEnableResetDefault,
  } = usePrompt({
    originalPrompt,
    defaultPrompt,
    placeholderOptions,
    maxLength: maxPromptLength,
    fetchDefaultPrompt: getDefaultPrompt,
    onTextChange: newValue => {
      const isDifferentFromOriginal = newValue !== originalPrompt
      const isDifferentModel = GPTModel?.value !== originalGPTModel?.value
      setEnableSaveConfig(isDifferentFromOriginal || isDifferentModel)

      setEnableResetDefault(newValue !== defaultPrompt)
    },
  })

  const handleChangeModel = (e: GPTModelOption) => {
    setGPTModel(e)
    setEnableSaveConfig(e.value !== originalGPTModel.value || promptText !== originalPrompt)
  }

  const handleSave = () => {
    const requestPayload = {
      prompt: promptText,
      model: GPTModel.value,
    }

    saveNLPConfig(activeBot.id, requestPayload).then(() =>
      alertSuccess('AI Knowledge settings are updated successfully.'),
    )

    setOriginalGPTModel(GPTModel)
    setOriginalPrompt(promptText)
    setEnableSaveConfig(false)
    setPromptError('')
  }
  const nlpSettingsEnabled = hasPermissionForBot(activeBot, 'nlpSettings')

  return (
    nlpSettingsEnabled && (
      <div>
        <S.SelectorContainer>
          <S.SubHeader>Model</S.SubHeader>
          <Select
            styles={customStyles}
            onBlurResetsInput={false}
            onSelectResetsInput={false}
            options={modelOptions}
            simpleValue
            value={GPTModel}
            onChange={handleChangeModel}
            isLoading={!GPTModel}
            placeholder={false}
            isDisabled={!GPTModel}
          />
        </S.SelectorContainer>
        <S.SubHeader>
          Custom prompt can be used to configure chatbot's features like personality, size of response, or just about
          anything.
        </S.SubHeader>
        <PromptForm
          onResetToDefault={handleResetToDefault}
          placeholderText={promptPlaceholderText}
          promptText={promptText}
          promptError={promptError}
          enableResetDefault={enableResetDefault}
          onPromptChange={handlePromptChange}
          displayResetNote={displayResetNote}
        />
        <S.Button onClick={handleSave} disabled={!promptText || !enableSaveConfig}>
          Save changes
        </S.Button>
      </div>
    )
  )
}
