import React from 'react'

import * as S from './RadioButtonGroup.style'

const options = [
  {
    label: 'United States',
    value: 'US1',
  },
  {
    label: 'Ireland',
    value: 'IE1',
  },
  {
    label: 'Australia',
    value: 'AU1',
  },
]

interface Props {
  value: string
  onChange: (a: string) => void
}

export const RadioButtonGroup: React.FC<Props> = ({ value, onChange }) => {
  return (
    <S.RadioButtonGroup>
      {options.map(option => (
        <S.Option key={option.value} isActive={option.value === value} onClick={() => onChange(option.value)}>
          {option.label}
        </S.Option>
      ))}
    </S.RadioButtonGroup>
  )
}
