import { useState, useEffect, ChangeEvent } from 'react'

function getRandomObject(array: string[]) {
  return array[Math.floor(Math.random() * array.length)]
}

type UseGreetingArgs = {
  /**
   * The current greeting text as retrieved from the server/config
   */
  originalGreeting: string
  /**
   * List of placeholder greeting strings to show at random when greeting is empty
   */
  placeholderOptions: string[]
  maxLength: number
  onTextChange?: (value: string) => void
}

export function useGreeting({ originalGreeting, placeholderOptions, maxLength, onTextChange }: UseGreetingArgs) {
  const [greetingText, setGreetingText] = useState(originalGreeting)
  const [greetingPlaceholderText, setGreetingPlaceholderText] = useState('')
  const [greetingError, setGreetingError] = useState('')

  useEffect(() => {
    setGreetingText(originalGreeting)
    setGreetingPlaceholderText(getRandomObject(placeholderOptions))
  }, [placeholderOptions, originalGreeting])

  const handleGreetingChange = async (e: ChangeEvent<HTMLTextAreaElement>) => {
    let value = e.target.value

    if (value.length > maxLength) {
      setGreetingError(`You've reached the max length — ${maxLength} characters`)
      value = value.substring(0, maxLength)
    } else {
      setGreetingError('')
    }

    setGreetingText(value)

    if (onTextChange) {
      onTextChange(value)
    }

    if (!value) {
      setGreetingPlaceholderText(getRandomObject(placeholderOptions))
    }
  }

  return {
    greetingText,
    greetingPlaceholderText,
    greetingError,
    setGreetingText,
    setGreetingPlaceholderText,
    setGreetingError,
    handleGreetingChange,
  }
}
