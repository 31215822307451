import React, { FC, useEffect, useRef, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { formatDate } from '../../../../helpers/formatDate/formatDate'
import MessageGroup from '../../../support/components/MessageGroup/MessageGroup'
import { getConversation } from '../../api/funnels'
import { isElementTop } from '../../../../helpers/isElementTop'
import LoaderSmall from '../../../../uiKit/loaders/loaderSmall'

import classes from './styles.module.scss'
import { ConversationType, MessageType } from '../../../../models/FunnelTypes'

interface Props extends RouteComponentProps {
  botId: number
  conversation: ConversationType
}

const Messages: FC<Props> = ({ botId, conversation }) => {
  const [messages, setMessages] = useState<MessageType[]>([])
  const [dates, setDates] = useState<any>([])
  const [page, setPage] = useState(0)
  const [hasNext, setHasNext] = useState(true)
  const [scrollPosition, setScrollPosition] = useState<null | number>(null)
  const [scrollToTop, serScrollToTop] = useState(false)
  const [loader, setLoader] = useState(true)
  const scrollContainer = useRef<any>(null)
  const messageIntoView = useRef<any>(null)

  useEffect(() => {
    const { messageId } = conversation

    if (scrollToTop && scrollPosition) {
      scrollContainer.current.scrollTop = scrollContainer.current.scrollHeight - scrollPosition
    }

    if (messages?.length) {
      updateMessageDates()
    }

    if (!messages.find(({ id }) => id === messageId) && hasNext) {
      getMessages().then(() => {
        setTimeout(() => {
          messageIntoView?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
        }, 0)
      })
    } else {
      if (loader === true) setLoader(false)
    }
  }, [messages])

  const updateMessageDates = () => {
    const dates = messages.map(item => item.lastEventAt.split('T')[0])
    setDates([...new Set(dates)])
  }

  const handleScroll = ({ target }: any) => {
    if (isElementTop(target) && hasNext) {
      setScrollPosition(scrollContainer.current.scrollHeight)
      serScrollToTop(true)
      getMessages()
    }
  }

  const getMessages = () => {
    const { conversationId } = conversation

    return getConversation({ botId, conversationId, page }).then(data => {
      setPage(page + 1)
      setMessages([...messages, ...data.content])
      updateHasNext(data.content.length)
    })
  }

  const updateHasNext = messagesLength => {
    if (messagesLength < 10) {
      setHasNext(false)
    }
  }

  return (
    <div className={classes.container} ref={scrollContainer} onScroll={handleScroll}>
      {loader ? (
        <div className={classes.loaderContainer}>
          <LoaderSmall showLoader={loader} />
        </div>
      ) : (
        dates
          .slice()
          .reverse()
          .map((date: any) => (
            <div key={date}>
              <p className={classes.date}>{formatDate(date)}</p>
              <MessageGroup
                date={date}
                messages={messages}
                messageIntoView={messageIntoView}
                messageId={conversation.messageId}
              />
            </div>
          ))
      )}
    </div>
  )
}

const mapStateToProps = (state: { activeBot: { id: any } }) => ({
  botId: state.activeBot?.id,
})

export default connect(mapStateToProps)(withRouter(Messages))
