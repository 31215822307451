import React from 'react'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

import { hasPermissionForBot } from '../../../../security'
import Heading from 'uiKit/texts/Heading'

import SettingsTab from './SettingsTab'

import * as S from './VoiceSettings.style'
import { BotType } from 'models/BotType.js'

const VoiceSettings = props => {
  const { activeBot } = props

  const nlpSettingsEnabled = hasPermissionForBot(activeBot, 'nlpSettings')

  return (
    <S.Container>
      <Heading>AI Voice Settings</Heading>
      <S.SubHeader>
        Select voices, modify prompt, and personalize greeting for the chatbot’s real-time voice responses.
      </S.SubHeader>
      {nlpSettingsEnabled && <SettingsTab />}
    </S.Container>
  )
}

const mapStateToProps = (state: { activeBot: BotType }) => ({
  activeBot: state.activeBot,
})

export default withRouter(connect(mapStateToProps)(VoiceSettings))
