import React, { useEffect, useState } from 'react'
import Select from 'react-select'

import { hasPermissionForBot } from '../../../../../security'
import { alertSuccess } from 'api'
import {
  getDefaultCustomPrompt,
  getVoiceConfig,
  getAvailableGPTVoiceModels,
  getAvailableGPTVoices,
  saveVoiceConfig,
} from 'tabs/nlp/api/websiteKnowledgeBase'

import PromptForm from '../PromptForm'
import GreetingForm from '../GreetingForm'

import * as S from './SettingsTab.style'
import { customStyles } from './SettingsTab.style'

import { usePrompt } from '../../hooks/usePrompt'
import { useGreeting } from '../../hooks/useGreeting'

type GPTOption = { value: string; label: string } | undefined

const promptPlaceholderOptions = [
  "You're a legal expert delivering clear advice...",
  "You're a medical consultant providing precise insights...",
  "You're a financial analyst guiding smart decisions...",
  "You're a customer support pro solving issues swiftly...",
  "You're a tech wizard tackling IT challenges effortlessly...",
  "You're a career coach sparking professional growth...",
  "You're a cybersecurity guardian protecting your data...",
  "You're a research specialist transforming data into clarity...",
  "You're an academic mentor inspiring quick learning...",
  "You're a travel advisor planning seamless journeys...",
]

const greetingPlaceholderOptions = [
  'Hello, ready to assist you...',
  'Hi there, your expert guide is here...',
  "Greetings, let's get started...",
  'Hey, how can I help you...',
  'Welcome, expert advice awaits...',
  "Good day, let's solve your query...",
  "Hi, let's tackle it together...",
  'Greetings, insights are on the way...',
  'Hello, expert support at your service...',
  "Welcome, let's dive in...",
]

const MAX_PROMPT_LENGTH = 10000
const MAX_GREETING_LENGTH = 255

export const SettingsTab = ({ activeBot }): JSX.Element => {
  const [enableSaveConfig, setEnableSaveConfig] = useState(false)
  const [GPTModel, setGPTModel] = useState<GPTOption>()
  const [voice, setVoice] = useState<GPTOption>()
  const [originalGPTModel, setOriginalGPTModel] = useState<GPTOption>()
  const [originalVoice, setOriginalVoice] = useState<GPTOption>()
  const [modelOptions, setModelOptions] = useState<GPTOption[]>([])
  const [voiceOptions, setVoiceOptions] = useState<GPTOption[]>([])

  const [originalGreeting, setOriginalGreeting] = useState('')
  const [originalPrompt, setOriginalPrompt] = useState('')
  const [defaultPrompt, setDefaultPrompt] = useState('')

  useEffect(() => {
    Promise.all([getAvailableGPTVoiceModels(), getAvailableGPTVoices(), getVoiceConfig(activeBot.id)]).then(
      ([GPTModels, GPTVoices, nlpConfig]) => {
        const optimizedModelOptions = GPTModels?.map(modelName => ({
          label: modelName,
          value: modelName,
        }))
        const optimizedVoiceOptions = GPTVoices?.map(voiceName => ({
          label: voiceName,
          value: voiceName,
        }))

        const selectedModelOption = optimizedModelOptions.find(model => model.value === nlpConfig.model)
        const selectedVoiceOption = optimizedVoiceOptions.find(v => v.value === nlpConfig.voice)

        setModelOptions(optimizedModelOptions)
        setVoiceOptions(optimizedVoiceOptions)

        setGPTModel(selectedModelOption)
        setVoice(selectedVoiceOption)

        setOriginalGPTModel(selectedModelOption)
        setOriginalVoice(selectedVoiceOption)

        setOriginalPrompt(nlpConfig.prompt || '')
        setOriginalGreeting(nlpConfig.greeting || '')
      },
    )
  }, [activeBot.id])

  useEffect(() => {
    if (!originalPrompt) {
      getDefaultPrompt().then(result => setDefaultPrompt(result))
    }
  }, [originalPrompt])

  const getDefaultPrompt = async (): Promise<string> => {
    return await getDefaultCustomPrompt({ voice: true })
  }

  const {
    promptText,
    promptPlaceholderText,
    promptError,
    enableResetDefault,
    displayResetNote,
    setPromptError,
    handlePromptChange,
    handleResetToDefault,
    setEnableResetDefault,
  } = usePrompt({
    originalPrompt,
    defaultPrompt,
    placeholderOptions: promptPlaceholderOptions,
    maxLength: MAX_PROMPT_LENGTH,
    fetchDefaultPrompt: getDefaultPrompt,
    onTextChange: newValue => {
      setEnableResetDefault(newValue !== defaultPrompt)
      setEnableSaveConfig(
        newValue !== originalPrompt ||
          greetingText !== originalGreeting ||
          GPTModel?.value !== originalGPTModel?.value ||
          voice.value !== originalVoice.value,
      )
    },
  })

  const { greetingText, setGreetingError, greetingPlaceholderText, greetingError, handleGreetingChange } = useGreeting({
    originalGreeting,
    placeholderOptions: greetingPlaceholderOptions,
    maxLength: MAX_GREETING_LENGTH,
    onTextChange: newValue => {
      setEnableSaveConfig(
        newValue !== originalGreeting ||
          promptText !== originalPrompt ||
          GPTModel?.value !== originalGPTModel?.value ||
          voice.value !== originalVoice.value,
      )
    },
  })

  const handleSave = () => {
    const requestPayload = {
      prompt: promptText,
      model: GPTModel?.value,
      voice: voice?.value,
      greeting: greetingText,
    }

    saveVoiceConfig(activeBot.id, requestPayload).then(() =>
      alertSuccess('AI Voice settings are updated successfully.'),
    )

    setOriginalGPTModel(GPTModel)
    setOriginalVoice(voice)
    setOriginalPrompt(promptText)
    setOriginalGreeting(greetingText)
    setPromptError('')
    setGreetingError('')

    setEnableSaveConfig(false)
  }

  const handleChangeModel = (selected: GPTOption) => {
    setGPTModel(selected)
    setEnableSaveConfig(
      selected?.value !== originalGPTModel?.value ||
        promptText !== originalPrompt ||
        greetingText !== originalGreeting ||
        voice.value !== originalVoice.value,
    )
  }

  const handleChangeVoice = (selected: GPTOption) => {
    setVoice(selected)
    setEnableSaveConfig(
      selected?.value !== originalVoice?.value ||
        GPTModel.value !== originalGPTModel?.value ||
        promptText !== originalPrompt ||
        greetingText !== originalGreeting,
    )
  }

  const nlpSettingsEnabled = hasPermissionForBot(activeBot, 'nlpSettings')
  if (!nlpSettingsEnabled) {
    return null
  }

  return (
    <div>
      <S.Selectors>
        <S.SelectorContainer>
          <S.SubHeader>Model</S.SubHeader>
          <Select
            styles={customStyles}
            onBlurResetsInput={false}
            onSelectResetsInput={false}
            options={modelOptions}
            simpleValue
            value={GPTModel}
            onChange={handleChangeModel}
            isLoading={!GPTModel}
            placeholder={false}
            isDisabled={!GPTModel}
          />
        </S.SelectorContainer>
        <S.SelectorContainer>
          <S.SubHeader>Voice</S.SubHeader>
          <Select
            styles={customStyles}
            onBlurResetsInput={false}
            onSelectResetsInput={false}
            options={voiceOptions}
            simpleValue
            value={voice}
            onChange={handleChangeVoice}
            isLoading={!voice}
            placeholder={false}
            isDisabled={!voice}
          />
        </S.SelectorContainer>
      </S.Selectors>

      <S.SubHeader>
        The first message the voice bot delivers to welcome the user and set the tone for the conversation.
      </S.SubHeader>
      <GreetingForm
        placeholderText={greetingPlaceholderText}
        greetingText={greetingText}
        greetingError={greetingError}
        onGreetingChange={handleGreetingChange}
      />

      <S.SubHeader>
        Custom prompt can be used to configure chatbot's features like personality, size of response, or just about
        anything.
      </S.SubHeader>
      <PromptForm
        onResetToDefault={handleResetToDefault}
        placeholderText={promptPlaceholderText}
        promptText={promptText}
        promptError={promptError}
        enableResetDefault={enableResetDefault}
        onPromptChange={handlePromptChange}
        displayResetNote={displayResetNote}
      />

      <S.Button onClick={handleSave} disabled={!promptText || !greetingText || !enableSaveConfig}>
        Save changes
      </S.Button>
    </div>
  )
}
