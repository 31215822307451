
import { BASE_URL } from '../../../../configs'
import {
  alertError,
  createJsonHeaders,
  logoutOnRequestOrResponseJson,
} from '../../../../api'

export async function getRecordingStatuses(botId, userId) {
  const recordingUrl = `${BASE_URL}/bot/${botId}/users/${userId}/recording-statuses`
  try {
    const response = await fetch(recordingUrl,
      {
        credentials: 'include',
        headers: createJsonHeaders(),
        method: 'GET',
      },
    )
    const json = await logoutOnRequestOrResponseJson(response)
    return json.data
  } catch (e) {
    alertError('Failed to load call recording statuses.')
  }
}
