import { theme } from 'constants/theme'
import styled from 'styled-components'

export const customStyles = {
  control: (provided, state) => {
    const error =
      !state.hasValue && !state.isFocused && state.selectProps.touched

    return {
      ...provided,
      border: error ? '1px solid red' : '1px solid var(--color-mischka-grey)',
      outline: 'none',
      boxShadow: 'none',
      borderRadius: 10,
      padding: '2px 6px',
      '&:hover': {
        border: error ? '1px solid red' : '1px solid rgba(53, 64, 82, 0.5)',
        boxShadow: 'none',
      },
    }
  },
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    color: 'var(--color-oxford-blue)',
    fontSize: 14,
  }),
  option: (provided, state) => {
    return {
      ...provided,
      fontSize: 14,
      cursor: 'pointer',
      backgroundColor: state.isFocused ? 'var(--color-hover-overlay-primary)' : 'var(--color-white)',
      color: 'var(--color-text-primary)',
      '&:hover': {
        fontSize: 14,
        backgroundColor: state.isFocused ? 'var(--color-hover-overlay-primary)' : 'var(--color-white)',
        color: 'var(--color-text-primary)',
      },
    }
  },
  menu: provided => ({
    ...provided,
    padding: 0,
    overflow: 'hidden',
  }),
  placeholder: provided => ({
    ...provided,
    color: 'rgba(53, 64, 82, 0.5)',
    fontSize: 14,
    width: 'max-content',
  }),
}

const SelectorContainer = styled.div`
  max-width: 400px;
  margin-bottom: 24px;
  position: relative;
  z-index: 3;

  & > div:first-child {
    margin-bottom: 8px;
  }
`

const SubHeader = styled.div`
  font-size: 12px;
  color: ${theme.tabs.nlp.subHeaderText};
`

const Button = styled.button`
  background: var(--color-button-primary);
  width: 128px;
  height: 48px;
  margin-bottom: 16px;
  border-radius: 10px;
  border: none;
  color: var(--color-text-on-primary);
  font-size: 16px;
  &:disabled {
    color: var(--color-text-on-primary);
    background: var(--color-disabled-fill);
  }
`

export { SelectorContainer, SubHeader, Button }
