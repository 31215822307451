import React, { useEffect, useState } from 'react'

import Search from '../Search/Search'
import DialogsSection from '../DialogsSection'
import {
  getAllSupportUsers,
  getAssignedSupportUsers,
  getNewRequestSupportUsers,
  getExpiredRequestSupportUsers,
  getAssignedToMeSupportUsers,
  getSupportUser,
} from '../../api/users'
import {
  saveAllSupportUsers,
  saveAssignedSupportUsers,
  saveAssignedToSupportUsers,
  saveExpiredSupportUsers,
  saveMoreAllSupportUsers,
  saveMoreAssignedUsers,
  saveMoreSupportAssignedToMeUsers,
  saveMoreSupportExpiredUsers,
  saveMoreSupportNewUsers,
  saveNewSupportUsers,
} from '../../actions/users'

import { useDebounce } from 'hooks/useDebounce'
import { isElementBottom } from 'helpers/isElementBottom'
import { isUrlEndsWithSupport } from 'helpers/isUrlEndsWithSupport'
import { getUrlEnd } from 'helpers/getUrlEnd'
import { SUPPORT_USERS_SIZE } from 'constants/userSize'
import { SupportUserType } from 'models/SupportUserType'

import * as S from './OpenRequests.styles'
import { connect } from 'react-redux'
import { BotType } from 'models/BotType'

interface Props {
  match: any
  assignedToMeUsers: SupportUserType[]
  newRequestsUsers: SupportUserType[]
  expiredRequestsUsers: SupportUserType[]
  assignedRequestsUsers: SupportUserType[]
  allUsers: SupportUserType[]
  adminUserId: number
  bot: BotType
}

const OpenRequestsComponent: React.FC<Props> = ({
  match,
  assignedToMeUsers,
  newRequestsUsers,
  expiredRequestsUsers,
  assignedRequestsUsers,
  allUsers,
  adminUserId,
  bot,
}) => {
  const [search, setSearch] = useState('')
  const [hasNext, setHasNext] = useState(true)
  const [loadingChatbot, setLoadingChatbot] = useState(false)
  const [loadingAssignedTo, setLoadingAssignedTo] = useState(false)
  const [loadingNew, setLoadingNew] = useState(false)
  const [loadingExpired, setLoadingExpired] = useState(false)
  const [loadingAssigned, setLoadingAssigned] = useState(false)

  const debouncedSearch = useDebounce(search, 500)

  useEffect(() => {
    if (!isUrlEndsWithSupport()) {
      getSupportUser(getUrlEnd(), match.params.botId)
    }
  }, [])

  useEffect(() => {
    setHasNext(true)
    loadMoreAssignedChats(saveAssignedSupportUsers, 0)
    loadMoreExpiredChats(saveExpiredSupportUsers, 0)
    loadMoreNewRequestChats(saveNewSupportUsers, 0)
    loadMoreAssignedToMeChats(saveAssignedToSupportUsers, 0)
    loadAllSupportUsers(saveAllSupportUsers, 0)
  }, [debouncedSearch])

  const handleScroll = event => {
    if (isElementBottom(event.target) && !loadingChatbot && hasNext) {
      loadAllSupportUsers(saveMoreAllSupportUsers, allUsers.length)
    }
  }

  const loadAllSupportUsers = (callback, offset) => {
    setLoadingChatbot(true)
    getAllSupportUsers(match.params.botId, debouncedSearch, offset)
      .then(res => {
        callback(res)
        if (res.length < SUPPORT_USERS_SIZE) setHasNext(false)
      })
      .finally(() => setLoadingChatbot(false))
  }

  const loadMoreAssignedToMeChats = (callback, offset) => {
    setLoadingAssignedTo(true)
    return getAssignedToMeSupportUsers(match.params.botId, adminUserId, debouncedSearch, offset)
      .then(users => {
        callback(users)
        return users || []
      })
      .finally(() => setLoadingAssignedTo(false))
  }

  const loadMoreExpiredChats = (callback, offset) => {
    setLoadingExpired(true)
    return getExpiredRequestSupportUsers(match.params.botId, debouncedSearch, offset)
      .then(users => {
        callback(users)
        return users || []
      })
      .finally(() => setLoadingExpired(false))
  }

  const loadMoreNewRequestChats = (callback, offset) => {
    setLoadingNew(true)
    return getNewRequestSupportUsers(match.params.botId, debouncedSearch, offset)
      .then(users => {
        callback(users)
        return users || []
      })
      .finally(() => setLoadingNew(false))
  }

  const loadMoreAssignedChats = (callback, offset) => {
    setLoadingAssigned(true)
    return getAssignedSupportUsers(match.params.botId, debouncedSearch, offset, adminUserId)
      .then(users => {
        callback(users, adminUserId)
        return users || []
      })
      .finally(() => setLoadingAssigned(false))
  }

  return (
    <>
      <S.SearchContainer>
        <Search search={search} handleSearch={setSearch} />
      </S.SearchContainer>
      <S.SectionWrap onScroll={handleScroll}>
        {!bot.voice && (
          <>
            <DialogsSection
              title="Assigned to me"
              search={debouncedSearch}
              users={assignedToMeUsers}
              loading={loadingAssignedTo}
              loadMoreUsers={() =>
                loadMoreAssignedToMeChats(saveMoreSupportAssignedToMeUsers, assignedToMeUsers.length)
              }
            />

            <DialogsSection
              title="New Requests"
              search={debouncedSearch}
              users={newRequestsUsers}
              loading={loadingNew}
              loadMoreUsers={() => loadMoreNewRequestChats(saveMoreSupportNewUsers, newRequestsUsers.length)}
            />

            <DialogsSection
              title="Expired Requests"
              search={debouncedSearch}
              users={expiredRequestsUsers}
              loading={loadingExpired}
              loadMoreUsers={() => loadMoreExpiredChats(saveMoreSupportExpiredUsers, expiredRequestsUsers.length)}
            />

            <DialogsSection
              title="Assigned"
              search={debouncedSearch}
              users={assignedRequestsUsers}
              loading={loadingAssigned}
              loadMoreUsers={() => loadMoreAssignedChats(saveMoreAssignedUsers, assignedRequestsUsers.length)}
            />
          </>
        )}

        <DialogsSection title="Chatbot" search={debouncedSearch} users={allUsers} loading={loadingChatbot} />
      </S.SectionWrap>
    </>
  )
}

const mapStateToProps = state => ({
  bot: state.activeBot,
})

export const OpenRequests = connect(mapStateToProps)(OpenRequestsComponent)
