import { useState, useEffect, ChangeEvent } from 'react'

function getRandomObject(array: string[]) {
  return array[Math.floor(Math.random() * array.length)]
}

type UsePromptArgs = {
  /**
   * The current prompt text as retrieved from the server/config
   */
  originalPrompt: string
  /**
   * The default prompt text (e.g., from your API `getDefaultCustomPrompt()`)
   */
  defaultPrompt: string
  /**
   * List of placeholder prompt strings to show at random when the prompt is empty
   */
  placeholderOptions: string[]
  maxLength: number
  /**
   * If you want to fetch the default prompt from an async API call,
   * pass a function here. Otherwise, we assume `defaultPrompt` is provided.
   */
  fetchDefaultPrompt?: () => Promise<string>
  onTextChange?: (value: string) => void
}

export function usePrompt({
  originalPrompt,
  defaultPrompt,
  placeholderOptions,
  maxLength,
  fetchDefaultPrompt,
  onTextChange,
}: UsePromptArgs) {
  const [promptText, setPromptText] = useState(originalPrompt)
  const [promptPlaceholderText, setPromptPlaceholderText] = useState('')
  const [promptError, setPromptError] = useState('')
  const [displayResetNote, setDisplayResetNote] = useState(false)
  const [enableResetDefault, setEnableResetDefault] = useState(false)

  useEffect(() => {
    setPromptText(originalPrompt)
    setPromptPlaceholderText(getRandomObject(placeholderOptions))
  }, [originalPrompt, placeholderOptions])

  useEffect(() => {
    setEnableResetDefault(promptText !== defaultPrompt)
  }, [promptText, defaultPrompt])

  const handlePromptChange = async (e: ChangeEvent<HTMLTextAreaElement>) => {
    let value = e.target.value

    if (value.length > maxLength) {
      setPromptError(`You've reached the max length — ${maxLength} characters`)
      value = value.substring(0, maxLength)
    } else {
      setPromptError('')
    }

    setPromptText(value)

    if (onTextChange) {
      onTextChange(value)
    }

    if (!value) {
      setPromptPlaceholderText(getRandomObject(placeholderOptions))
    }
  }

  const handleResetToDefault = async () => {
    if (fetchDefaultPrompt) {
      const result = await fetchDefaultPrompt()
      setPromptText(result)
    } else {
      setPromptText(defaultPrompt)
    }

    setDisplayResetNote(true)
    setPromptError('')
    setEnableResetDefault(false)

    if (onTextChange) {
      onTextChange(defaultPrompt)
    }
  }

  return {
    promptText,
    promptPlaceholderText,
    promptError,
    displayResetNote,
    enableResetDefault,
    setPromptText,
    setPromptPlaceholderText,
    setPromptError,
    setDisplayResetNote,
    setEnableResetDefault,
    handlePromptChange,
    handleResetToDefault,
  }
}
