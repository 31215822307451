import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import AudioPlayer from '../../../../uiKit/AudioPlayer/AudioPlayer'
import { getCallRecording } from './recordingApi'

const CallRecordingPlayer = ({ activeBot, activeUser, conversationId }) => {
  const [audioUrl, setAudioUrl] = useState('')
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    console.log('initializing audioUrl. activeBot:', activeBot, 'activeUser:', activeUser)
    getCallRecording(activeBot.id, conversationId).then(blob => {
      const objectUrl = URL.createObjectURL(blob)
      setAudioUrl(objectUrl)
      setIsLoading(false)
      console.log('Initialized audioUrl:', audioUrl)
    })

    return () => {
      if (audioUrl) {
        URL.revokeObjectURL(audioUrl)
      }
    }
  }, [])

  useEffect(() => {
    console.log('audioUrl:', audioUrl)
  }, [audioUrl])

  return !isLoading && <AudioPlayer src={audioUrl} />
}

const mapStateToProps = state => ({
  activeBot: state.activeBot,
  activeUser: state.activeUser,
})

export default connect(mapStateToProps)(CallRecordingPlayer)
