import React from 'react'
import H5AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'

import './AudioPlayer.css'
import { DownloadIcon } from '../icons/DownloadIcon'


const AudioPlayer = ({ src }) => {
  const handleDownload = () => {
    const link = document.createElement('a')
    link.href = src
    link.download = src.split('/').pop() || 'audio'
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <div className="w-full max-w-2xl mx-auto">
      <H5AudioPlayer
        src={src}
        className="mb-4 border shadow-sm bg-light"
        customControlsSection={[RHAP_UI.MAIN_CONTROLS]}
        customProgressBarSection={[
          RHAP_UI.CURRENT_TIME,
          RHAP_UI.PROGRESS_BAR,
          RHAP_UI.DURATION,
          RHAP_UI.VOLUME_CONTROLS,
          <div key="download" className="ms-4 grow-1">
            <span onClick={handleDownload}>
              <DownloadIcon styles={{ cursor: 'pointer' }} />
            </span>
          </div>,
        ]}
        customAdditionalControls={[]}
        autoPlayAfterSrcChange={false}
        showJumpControls={false}
        layout="horizontal-reverse"
      />
    </div>
  )
}

export default AudioPlayer
