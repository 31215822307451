import React from 'react'

import { KnowledgeBaseReplies } from 'tabs/nlp/components/KnowledgeBaseReplies/KnowledgeBaseReplies'
import AIActions from 'tabs/nlp/components/AIActions/AIActions'
import Intents from '../tabs/nlp/components/Intents'
import Triggers from '../tabs/nlp/components/Triggers'
import TrainingContainer from '../tabs/nlp/components/TrainingContainer'
import KnowledgeBase from '../tabs/nlp/components/knowledgeBase/KnowledgeBase'
import VoiceSettings from 'tabs/nlp/components/voiceSettings/VoiceSettings'

export type nlpRoute = {
  path: string
  name: string
  component: () => JSX.Element
}

export const nlpRoutes: nlpRoute[] = [
  {
    path: 'voice-settings',
    name: 'Voice Settings',
    component: () => <VoiceSettings />,
  },
  {
    path: 'ai-knowledge',
    name: 'AI Knowledge',
    component: () => <KnowledgeBase />,
  },
  {
    path: 'ai-knowledge-replies',
    name: 'AI Knowledge replies',
    component: () => <KnowledgeBaseReplies />,
  },
  {
    path: 'ai-actions',
    name: 'AI Actions',
    component: () => <AIActions />,
  },
  {
    path: 'faq',
    name: 'FAQ',
    component: () => <Intents isFaq={true} tableTitle="FAQ" createButton="Create FAQ" />,
  },
  {
    path: 'intents',
    name: 'Intents',
    component: () => <Intents isFaq={false} tableTitle="Intents" createButton="Create Intent" />,
  },
  {
    path: 'triggers',
    name: 'Triggers',
    component: () => <Triggers />,
  },
  {
    path: 'training',
    name: 'Training',
    component: () => <TrainingContainer />,
  },
]

export const voiceNlpRoutes: nlpRoute[] = [
  {
    path: 'voice-settings',
    name: 'Voice Settings',
    component: () => <VoiceSettings />,
  },
  {
    path: 'ai-actions',
    name: 'AI Actions',
    component: () => <AIActions />,
  },
]
