/**
 * Created by Admin on 17.04.2018.
 */
import React from 'react'

import { TextIcon } from '../../../../uiKit/icons/TextIcon'
import { TypingIcon } from '../../../../uiKit/icons/TypingIcon'
import { RedirectToAtomIcon } from '../../../../uiKit/icons/RedirectToAtomIcon'
import { SetAttributeIcon } from '../../../../uiKit/icons/SetAttributeIcon'
import { APICallIcon } from '../../../../uiKit/icons/APICallIcon'

import {
  CONDITIONAL_REDIRECT_TYPE,
  RANDOM_REDIRECT_TYPE,
  SAVE_USER_INPUT_TYPE,
  SET_ATTRIBUTE_TYPE,
  TEXT_TYPE,
  USER_INPUT_TYPE,
  API_CALL_TYPE,
} from '../../constants/messageTypes'

export const availableDefaultMessages = [
  {
    name: 'Typing',
    type: 'typing',
    icon: <TypingIcon width="16" height="16" color={'var(--color-button-secondary)'} />,
    iconLarge: <TypingIcon width="24" height="24" color={'var(--color-button-secondary)'} />,
    description: 'Send typing indicator',
    newMessage: {
      senderAction: 'typing_on',
      time: 3,
    },
    iconName: 'TextIcon',
  },
  {
    name: 'Text',
    icon: <TextIcon width="16" height="16" color={'var(--color-button-secondary)'} />,
    iconLarge: <TextIcon width="24" height="24" color={'var(--color-button-secondary)'} />,
    description: 'Send a text in chat',
    newMessage: {
      texts: [''],
      enableReaction: false,
      type: TEXT_TYPE,
    },
  },
  {
    name: 'Smart redirect',
    icon: <RedirectToAtomIcon width="16" height="16" color={'var(--color-button-secondary)'} />,
    iconLarge: <RedirectToAtomIcon width="24" height="24" color={'var(--color-button-secondary)'} />,
    description: 'Redirects user to another atom',
    type: CONDITIONAL_REDIRECT_TYPE,
  },
  {
    name: 'Set attribute',
    icon: <SetAttributeIcon width="16" height="16" color={'var(--color-button-secondary)'} />,
    iconLarge: <SetAttributeIcon width="24" height="24" color={'var(--color-button-secondary)'} />,
    description: 'Set a value for an attribute',
    type: SET_ATTRIBUTE_TYPE,
  },
  {
    name: 'API call',
    icon: <APICallIcon color={'var(--color-button-secondary)'} />,
    iconLarge: <APICallIcon color={'var(--color-button-secondary)'} />,
    description: 'Define an HTTP request to some external API',
    type: API_CALL_TYPE,
  },
]

export const availableVoiceMessages = availableDefaultMessages.filter(msg =>
  [CONDITIONAL_REDIRECT_TYPE, SET_ATTRIBUTE_TYPE, API_CALL_TYPE].includes(msg.type),
)

export const galleryMessage = {
  type: 'gallery',
  newMessage: {
    attachment: {
      type: 'template',
      payload: {
        template_type: 'generic',
        elements: [
          {
            title: '',
            subtitle: '',
            image_url: '',
            buttons: [],
          },
        ],
      },
    },
    enableReaction: false,
  },
}

export const attachmentMessage = {
  type: 'attachment',
  newMessage: {
    attachment: {
      type: 'image',
      payload: {
        url: '',
      },
    },
    enableReaction: false,
  },
}

export const availableOtherMessages = [
  {
    name: 'Media template',
    newMessage: {
      attachment: {
        type: 'image',
        payload: {
          url: '',
        },
      },
    },
  },
  {
    name: 'User Input',
    type: USER_INPUT_TYPE,
    newMessage: {
      type: USER_INPUT_TYPE,
      parameter: '',
      next_atom: '',
    },
  },
]

export const randomRedirectInitialState = {
  type: RANDOM_REDIRECT_TYPE,
  randomRedirect: {
    randomAtoms: [
      {
        atomName: '',
        number: 1,
      },
    ],
    sendFirstAtomOnlyOnce: false,
  },
}

export const setAttributeMessage = {
  type: SET_ATTRIBUTE_TYPE,
  attributes: [
    {
      id: '',
      value: '',
      name: '',
    },
  ],
}

export const setTextToggle = {
  senderAction: 'input_on',
}

export const saveUserInput = {
  type: SAVE_USER_INPUT_TYPE,
  saveUserInput: {
    validation: 'NONE',
    customValidationRegex: null,
    onValidationSuccessAtomId: null,
    onValidationFailAtomId: null,
    saveAsAttribute: null,
  },
}

export const smartRedirect = {
  type: CONDITIONAL_REDIRECT_TYPE,
  conditionalRedirect: {
    conditionGroups: null,
    defaultRedirect: null,
  },
}

export const conditionGroup = {
  expressions: [
    {
      attribute: null,
      operator: 'IS',
      value: null,
    },
  ],
  conjunction: 'AND',
  redirectTo: null,
}

export const apiCall = {
  type: API_CALL_TYPE,
  apiCall: {
    addUserData: false,
    body: null,
    headers: [],
    method: 'GET',
    url: null,
    attributes: [],
  },
}
