import styled from 'styled-components'

// Auto-resizing textarea. See https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/

const GrowWrap = styled.div<{ maxHeight: number }>`
  display: grid;

  max-height: ${({ maxHeight }) => maxHeight}px;
  width: 100%;
  overflow: hidden;
`

const GrowWrapAfter = styled.div<{ maxHeight: number }>`
  content: attr(data-replicated-value) ' ';
  white-space: pre-wrap;
  visibility: hidden;

  max-height: ${({ maxHeight }) => maxHeight}px;
  padding: 10px;

  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
`

const Textarea = styled.textarea<{ showScroll: boolean; minHeight: number; maxHeight: number; isError: boolean }>`
  resize: none;
  position: relative;

  overflow: hidden;
  overflow-y: ${({ showScroll }) => (showScroll ? 'auto' : 'none')};

  min-height: ${({ minHeight }) => minHeight}px;
  max-height: ${({ maxHeight }) => maxHeight}px;
  height: auto;

  border-color: ${({ isError }) => isError && 'var(--color-text-error)'};

  /* Place on top of each other */
  grid-area: 1 / 1 / 2 / 2;
`

export { GrowWrap, GrowWrapAfter, Textarea }
