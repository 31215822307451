import React from 'react'
import { connect } from 'react-redux'

import { hasPermissionForBot } from '../../../../../security'
import { KnowledgeBaseSubTabMap } from 'tabs/nlp/constants'

import * as S from './RadioButtonGroup.style'
import { CogIcon } from '../../../../../uiKit/icons/Icons'
import { BotType } from 'models/BotType.js'

const options = [
  {
    label: 'Website',
    value: KnowledgeBaseSubTabMap.WEBSITE,
  },
  {
    label: 'File',
    value: KnowledgeBaseSubTabMap.FILE,
  },
  {
    label: 'Text',
    value: KnowledgeBaseSubTabMap.TEXT,
  },
  {
    label: 'Settings',
    value: KnowledgeBaseSubTabMap.SETTINGS,
  },
]

interface Props {
  value: string
  onChange: (a: string) => void
  activeBot: BotType
}

const handleSettingsClick = (value: string) => {
  if (value === KnowledgeBaseSubTabMap.SETTINGS) {
    return 'var(--color-text-on-secondary)'
  }
  return 'var(--color-text-secondary)'
}

const RadioButtonGroup: React.FC<Props> = ({ value, onChange, activeBot }) => {
  const nlpSettingsEnabled = hasPermissionForBot(activeBot, 'nlpSettings')

  const enabledOptions = !nlpSettingsEnabled
    ? options.filter(option => option.value !== KnowledgeBaseSubTabMap.SETTINGS)
    : options

  return (
    <S.RadioButtonGroup>
      {enabledOptions.map(option => (
        <S.Option key={option.value} isActive={option.value === value} onClick={() => onChange(option.value)}>
          {option.value !== KnowledgeBaseSubTabMap.SETTINGS ? (
            option.label
          ) : (
            <CogIcon width={40} height={20} color={handleSettingsClick(value)} />
          )}
        </S.Option>
      ))}
    </S.RadioButtonGroup>
  )
}

const mapStateToProps = (state: { activeBot: BotType }) => ({
  activeBot: state.activeBot,
})

export default connect(mapStateToProps)(RadioButtonGroup)
