
import { BASE_URL } from '../../../../configs'
import {
  alertError,
  createJsonHeaders,
  logoutOnRequestOrResponseBlob,
} from '../../../../api'

export async function getCallRecording(botId, conversationId) {
  const recordingUrl = `${BASE_URL}/bot/${botId}/conversations/${conversationId}/recording`
  try {
    const response = await fetch(recordingUrl,
      {
        credentials: 'include',
        headers: createJsonHeaders(),
        method: 'GET',
      },
    )
    return await logoutOnRequestOrResponseBlob(response)
  } catch (e) {
    alertError('Failed to load call recording.')
  }
}
